import React, { PureComponent } from 'react';
import { Modal, Button, Spin } from 'antd';
import { DIALOG_RECALL } from '../../omsConstants';
import { HotTable } from '@handsontable/react';
import {
  executeRouteHoldingGridColumns,
  recallTableColumns,
  recallTradesTableColumns,
  recallTargetRatioColumns
} from './GridColumnMap';
import hotTableUtils from 'common/ui/hotTableUtils';
import client from '../../api/client';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../../common/ui/agGridUtils';
import _ from 'lodash';
import SplitPane from 'react-split-pane';
import orderClient from 'features/order/api/client';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import { DIALOG_EXECUTE_QUANT_TRADES } from 'features/order/orderConstants';

class RecallDialogs extends PureComponent {
  state = {
    isInitialized: false,
    headerData: [
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() }
    ],
    fundMap: {
      PLATUSD: ['PMSF', 'VCC'],
      PLATCNY: ['CVF', 'DCL']
    },
    revertFundMap: {
      PMSF: 'PLATUSD',
      VCC: 'PLATUSD',
      CVF: 'PLATCNY',
      DCL: 'PLATCNY'
    },
    trades: [],
    holdings: [],
    targetRatios: [],
    allocationPercents: [],
    selectedData: null,
    holdingsByTicker: {},
    headerGridWrapperStyle: {
      width: '100%',
      height: '280px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    tradeGridWrapperStyle: {
      width: '100%',
      height: '280px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    headerGridSettings: null,
    holdingsGridSettings: agGridUtils.createSettings({
      columnDefs: executeRouteHoldingGridColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    tradeGridSetting: hotTableUtils.createSettings({
      columns: recallTradesTableColumns,
      rowHeaders: true,
      contextMenu: {}
    }),
    holdingsGridWrapperStyle: {
      width: '100%',
      height: '300px'
    },
    targetRatioGridSettings: agGridUtils.createSettings({
      columnDefs: recallTargetRatioColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    targetRatioGridWrapperStyle: {
      width: '100%',
      height: '300px'
    },
    secMap: {}
  };

  componentDidMount() {
    this._initData();
  }

  queryHoldings = tickers => {
    const rawTicker = tickers.map(r => {
      if (r.endsWith('C1 Equity') || r.endsWith('C2 Equity'))
        return r
          .replace('C1 Equity', 'CH Equity')
          .replace('C2 Equity', 'CH Equity');
      return r;
    });
    client
      .queryHoldings(rawTicker)
      .then(resp => {
        const holdingsByTicker = _.isEmpty(resp)
          ? {}
          : _.groupBy(resp, 'ticker');
        this.setState(
          {
            holdingsByTicker
          },
          this._calcTrades
        );
      })
      .then(err => {
        console.log(err);
      });
  };

  _calcTrades = () => {
    const { headerData, holdingsByTicker } = this.state;
    const trades = [];
    const rows = _.isEmpty(headerData)
      ? []
      : headerData.filter(r => r.fundCode && r.custodian && r.ticker && r.qty);
    if (!_.isEmpty(rows) && !_.isEmpty(holdingsByTicker)) {
      rows.forEach(r => {
        const { qty, reshortQty, lotSize, ticker, id } = r;
        const holdings = this._filterHoldings(r);
        const allocations = this.distributeAmount(
          holdings.map(e => Math.abs(e.eod.qty)),
          qty,
          lotSize
        );
        const reShortAllocations = this.distributeAmount(
          holdings.map(e => Math.abs(e.eod.qty)),
          reshortQty,
          lotSize
        );
        const datas = holdings.map((r, index) => {
          const allocationQty = allocations[index];
          const reShortQty = reShortAllocations[index];
          return {
            fundCode: r.fundCode,
            bookCode: r.bookCode,
            custodian: r.custodianCode,
            ticker: ticker,
            cfdType: r.cfdType,
            qty: allocationQty,
            reShortQty: reShortQty,
            strategy: r.strategyCode,
            sourceHeaderId: id
          };
        });
        trades.push(...datas);
      });
    }
    this.setState(
      {
        trades
      },
      () => {
        this._setSelectedDatas(headerData);
      }
    );
  };

  distributeAmount = (weights, total, lotSize) => {
    let totalWeight = weights.reduce((acc, curr) => acc + curr, 0);

    let allocations = weights.map(weight => {
      let ratio = (weight / totalWeight) * total;
      let allocation = Math.floor(ratio / lotSize) * lotSize;
      allocation = Math.min(allocation, weight);
      return allocation;
    });

    let sum = allocations.reduce((acc, curr) => acc + curr, 0);
    let remaining = total - sum;
    if (remaining > 0) {
      let indices = allocations
        .map((_, index) => index)
        .filter(
          index =>
            allocations[index] < weights[index] &&
            weights[index] - allocations[index] >= lotSize
        );
      let fillCount = 0;
      while (remaining > 0 && fillCount < indices.length) {
        let index = indices[fillCount];
        let availableSpace = weights[index] - allocations[index];
        let addTo = Math.min(remaining, availableSpace, lotSize);
        allocations[index] += addTo;
        remaining -= addTo;
        fillCount++;
      }
    }

    return allocations;
  };

  _initData = () => {
    const { funds } = this.props.settings;
    Promise.all([client.getExecutionOptions(), client.getTargetRatio()])
      .then(([{ custodians }, targetRatios]) => {
        const headerGridSettings = this._createHeaderGridSettings(
          custodians.map(b => b.custodianCode),
          _.uniq(funds.map(c => c.name))
        );

        _.delay(() => {
          this.setState({
            isInitialized: true,
            targetRatios,
            headerGridSettings
          });
        }, 150);
      })
      .catch(ex => {
        console.log(ex);
        this.setState({
          isInitialized: true,
          submitStatus: 'ERROR'
        });
      });
  };

  _createHeaderGridSettings = (custodianCodes, funds) => {
    const optionsMapping = {
      custodian: custodianCodes,
      fundCode: funds
    };
    const columns = recallTableColumns.map(c => {
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    const contextMenu = {
      items: {
        remove_row: {},
        clone: {
          name: 'Clone row',
          callback: () => {
            // Must delay below operation, otherwise handsontable will throw exception.
            _.delay(() => this._cloneHeaderRow(), 100);
          }
        }
      }
    };
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu,
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _cloneHeaderRow = () => {
    const { headerData, selectedData } = this.state;

    const clonedData = {
      ...selectedData,
      id: _.uniqueId(),
      qty: null
    };

    const row = headerData.findIndex(r => r.id === headerData.id);
    const updatedData = [
      ...headerData.slice(0, row + 1),
      ...[clonedData],
      ...headerData.slice(row + 1)
    ];

    this.setState({ headerData: updatedData });
  };

  _applyChange = () => {
    const { headerData } = this.state;
    const tickers = headerData.map(r => r.ticker).filter(r => r);
    this.queryHoldings(tickers);
  };

  _onDataSelectionChanged = (row, col, row2, col2) => {
    const { headerData } = this.state;
    // const route = routes[row];
    // if (route === selectedRoute) return;

    const selectedData = _.slice(headerData, row, row2 + 1);

    this._setSelectedDatas(selectedData);
  };

  _beforeCellChange = (changes, source) => {
    if (source !== 'edit') return;
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) {
      return this._handleChanges(realChanges);
    }

    return true;
  };

  _normalizeField = (field, value) => {
    if(!value) return {[field]: value}
    if(field === 'fundCode') { 
      return  {[field]: value.replace(/\s+/g, '').toUpperCase()}
    }
    return {[field]: value}
  }

  _handleChanges = async realChanges => {
    const { headerData } = this.state;
    const updateData = await Promise.all(
      realChanges.map(async r => {
        const [row, field, oldValue, newValue] = r;
        const detail = headerData[row];
        let result = {
          ...detail,
          ...this._normalizeField(field, newValue)
        };
        if (
          oldValue !== newValue &&
          field === 'ticker' &&
          !_.isEmpty(newValue)
        ) {
          const ticker = newValue.endsWith('Equity')
            ? newValue
            : `${newValue} Equity`;
          const infos = await orderClient
            .getSecurities([{ ticker }])
            .catch(error => console.log(error));
          if (!_.isEmpty(infos) && !_.isEmpty(infos[0])) {
            const secInfo = infos[0];
            result = {
              ...result,
              lotSize: secInfo.lotSize
            };
          }
        }
        return result;
      })
    );
    const data = headerData.map(r => {
      const updateD = updateData.filter(e => e.id === r.id);
      if (!_.isEmpty(updateD)) {
        return {
          ...updateD[0]
        };
      }
      return { ...r };
    });
    _.delay(() => {
      this.setState({
        headerData: data
      });
    }, 100);
    return false;
  };

  _filterHoldings = data => {
    const { holdingsByTicker, fundMap } = this.state;
    const { fundCode, custodian, ticker, cfdType } = data;
    const funds = fundMap[fundCode] ? fundMap[fundCode] : [fundCode];
    return _.sortBy(holdingsByTicker[ticker]).filter(
      r =>
        funds.includes(r.fundCode) &&
        r.cfdType === cfdType &&
        r.custodianCode === custodian &&
        r.eod &&
        r.eod.qty < 0
    );
  };

  _setSelectedDatas = selectedDatas => {
    if (_.isEmpty(selectedDatas)) {
      this.setState({
        selectedData: null,
        holdings: []
      });
      return;
    }

    const data = _.first(selectedDatas);

    this.setState({
      selectedData: data,
      holdings: this._filterHoldings(data)
    });
  };

  _createHeaderTable = () => {
    const {
      headerGridWrapperStyle,
      headerGridSettings,
      headerData
    } = this.state;

    return (
      <div style={headerGridWrapperStyle}>
        <HotTable
          ref={this.hotTblRef}
          data={headerData}
          manualColumnResize={true}
          beforeChange={this._beforeCellChange}
          afterSelectionEnd={this._onDataSelectionChanged}
          {...headerGridSettings}
          licenseKey="non-commercial-and-evaluation"
        ></HotTable>
      </div>
    );
  };

  _createTradeTable = () => {
    const { tradeGridWrapperStyle, tradeGridSetting, trades } = this.state;

    return (
      <div style={tradeGridWrapperStyle}>
        <HotTable
          ref={this.hotTblRef}
          data={trades}
          manualColumnResize={true}
          {...tradeGridSetting}
          licenseKey="non-commercial-and-evaluation"
        ></HotTable>
      </div>
    );
  };

  _onHoldingsGridReady = params => {
    this.holdingsGridApi = params.api;

    const COLUMNS_KEY = 'recall-holding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onTargetRatioGridReady = params => {
    this.targetRatioGridApi = params.api;

    const COLUMNS_KEY = 'recall-ratio-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.targetRatioGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _createHoldingsGrid = () => {
    const {
      holdingsGridSettings,
      holdingsGridWrapperStyle,
      holdings
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={holdings}
          {...holdingsGridSettings}
          onGridReady={this._onHoldingsGridReady}
        />
      </div>
    );
  };

  _createTargetRatioGrid = () => {
    const {
      targetRatioGridSettings,
      targetRatioGridWrapperStyle,
      targetRatios,
      selectedData = {},
      trades
    } = this.state;

    const books = _.isEmpty(trades)
      ? []
      : trades
          .filter(r => r.sourceHeaderId === selectedData.id)
          .map(r => r.bookCode);

    const rowData =
      _.isEmpty(selectedData) || _.isEmpty(targetRatios)
        ? []
        : targetRatios.filter(
            r =>
              r.sourceFundCode === selectedData.fundCode &&
              r.targetFundCode === selectedData.fundCode &&
              books.includes(r.sourceBookCode)
          );

    return (
      <div
        style={targetRatioGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          rowData={rowData}
          {...targetRatioGridSettings}
          onGridReady={this._onTargetRatioGridReady}
        />
      </div>
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_RECALL);
  };

  openQuantTradesDialog = trades => {
    this.props.openDialog(DIALOG_EXECUTE_QUANT_TRADES, {
      createNew: true,
      trades
    });
  };

  submitData = () => {
    const { trades, revertFundMap } = this.state;
    if (_.isEmpty(trades)) return;
    const submitData = [];
    trades.forEach(r => {
      const preRoute = {
        fundCode: r.fundCode,
        bookCode: r.bookCode,
        custodian: r.custodian,
        strategy: r.strategy,
        venue: 'MANUAL',
        notes: 'RECALL',
        cfdType: r.cfdType
      };
      const covrRoute = {
        ...r,
        fundCode: revertFundMap[r.fundCode]
          ? revertFundMap[r.fundCode]
          : r.fundCode,
        quantity: r.qty,
        algoCode: 'INLINE',
        orderType: 'MKT',
        timeInForce: 'DAY',
        pmReason: '[RECALL]',
        side: 'COVR',
        preRoutes: [{ ...preRoute, qty: r.qty, side: 'COVR' }]
      };
      submitData.push(covrRoute);
      if (r.reShortQty && r.reShortQty > 0) {
        const reShortRoute = {
          ...covrRoute,
          side: 'SHRT',
          quantity: r.reShortQty,
          preRoutes: [{ ...preRoute, qty: r.reShortQty, side: 'SHRT' }]
        };
        submitData.push(reShortRoute);
      }
    });
    this.openQuantTradesDialog(submitData);
  };

  render() {
    const { isInitialized } = this.state;

    return (
      <Modal
        width={1600}
        maskClosable={false}
        title="Recall"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        bodyStyle={{ paddingTop: '5px' }}
        footer={[
          <Button key="submit" type="primary" onClick={this.submitData}>
            Submit
          </Button>,
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <div style={{ marginTop: '10px', textAlign: 'right' }}>
          <Button type="primary" size="small" onClick={this._applyChange}>
            Calc
          </Button>
        </div>
        <Spin tip="Initializing..." spinning={!isInitialized}>
          <SplitPane
            split="horizontal"
            defaultSize="50%"
            style={{ position: 'relative' }}
          >
            <SplitPane
              split="vertical"
              defaultSize="40%"
              style={{ position: 'relative' }}
            >
              {this._createHeaderTable()}
              {this._createTradeTable()}
            </SplitPane>
            <SplitPane
              split="vertical"
              defaultSize="70%"
              style={{ position: 'relative' }}
            >
              {this._createHoldingsGrid()}
              {this._createTargetRatioGrid()}
            </SplitPane>
          </SplitPane>
        </Spin>
      </Modal>
    );
  }
}

export default RecallDialogs;
